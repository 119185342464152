var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h1',{staticClass:"title text-size7 font-bold"},[_vm._v(_vm._s(_vm.$t("common.documents")))]),_c('div',{staticClass:"main-documents flex flex-col gap-16 py-32"},[_c('HousfyLink',{attrs:{"to":{
        name: 'documentsInvoices',
        params: {
          propertyUuid: _vm.propertyUuid,
        },
      },"tracking":"link_documents_invoices","data-qa":"documents-invoices"}},[_c('HousfyDocumentCard',{attrs:{"icon":"illustrations/documents-bills","text":_vm.$t('common.invoices'),"subtext":_vm.$t('common.incomeAndExpenses'),"size":"48"}})],1),_c('HousfyLink',{attrs:{"to":{
        name: 'documentsProperty',
        params: {
          propertyUuid: _vm.propertyUuid,
        },
      },"tracking":"link_documents_documents-property","data-qa":"documents-property"}},[_c('HousfyDocumentCard',{attrs:{"icon":"illustrations/documents-home","text":_vm.$t('common.documentsProperty'),"size":"48"}})],1),(_vm.currentLeasing)?_c('HousfyLink',{attrs:{"to":{
        name: 'documentsLeasing',
        params: {
          propertyUuid: _vm.propertyUuid,
          leasingId: _vm.currentLeasing.leasingId,
        },
      },"tracking":"link_documents_current-leasing","data-qa":"documents-current-leasing"}},[_c('HousfyDocumentCard',{attrs:{"icon":"illustrations/documents-rent","text":_vm.$t('common.currentLeasing'),"subtext":_vm.currentLeasing.periodDate,"size":"48"}})],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }