
import { Component, Vue, Prop } from "vue-property-decorator";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import { HousfyDocumentCard } from "housfy-ui-lib";
import { IRentalLeasingFormatted } from "@/models/RentalLeasing";

@Component({
  name: "DocumentsMainDocuments",
  components: {
    HousfyLink,
    HousfyDocumentCard,
  },
})
export default class DocumentsMainDocuments extends Vue {
  @Prop({ type: String, required: true })
  propertyUuid!: string;
  @Prop({ type: Object, default: () => {} })
  currentLeasing!: IRentalLeasingFormatted;
}
